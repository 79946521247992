import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout';
import { Book, Brain, Clock, Star, Settings, Shield, Wrench, ArrowRight, AlertCircle, History } from 'lucide-react';

const Dashboard = () => {
  const [selectedAgeGroup, setSelectedAgeGroup] = useState('young-children');
  const [complexity, setComplexity] = useState(1);
  const [storyLength, setStoryLength] = useState('short');
  const navigate = useNavigate();


  const subscriptionData = {
    plan: 'Standard',
    totalHours: 6,
    usedHours: 3.5,
    remainingHours: 2.5,
    validUntil: '15.12.2024',
    recentStories: [
      { title: 'Das Geheimnis des alten Waldes', duration: 45, date: '14.11.2024' },
      { title: 'Abenteuer im Weltraum', duration: 30, date: '12.11.2024' },
      { title: 'Die magische Bibliothek', duration: 35, date: '10.11.2024' }
    ]
  };

  const progressPercentage = (subscriptionData.usedHours / subscriptionData.totalHours) * 100;

  const ageGroups = {
    'toddlers': {
      label: 'Kleinkinder (2-3 Jahre)',
      description: 'Einfache, kurze Geschichten mit vielen Wiederholungen',
      settings: {
        vocabulary: 'Sehr einfach',
        complexity: 'Minimal',
        length: '2-3 Minuten',
        themes: ['Tiere', 'Familie', 'Alltag']
      }
    },
    'young-children': {
      label: 'Vorschulkinder (4-5 Jahre)',
      description: 'Bildhafte Geschichten mit klaren Handlungen',
      settings: {
        vocabulary: 'Einfach',
        complexity: 'Gering',
        length: '5-7 Minuten',
        themes: ['Freundschaft', 'Abenteuer', 'Natur', 'Fantasie']
      }
    },
    'school-children': {
      label: 'Grundschulkinder (6-9 Jahre)',
      description: 'Spannende Geschichten mit Lerneffekt',
      settings: {
        vocabulary: 'Altersgerecht',
        complexity: 'Mittel',
        length: '10-15 Minuten',
        themes: ['Wissenschaft', 'Geschichte', 'Weltraum', 'Technik']
      }
    },
    'older-children': {
      label: 'Ältere Kinder (10-12 Jahre)',
      description: 'Komplexere Geschichten mit Wissensvertiefung',
      settings: {
        vocabulary: 'Erweitert',
        complexity: 'Hoch',
        length: '15-20 Minuten',
        themes: ['Naturwissenschaften', 'Weltgeschichte', 'Technologie', 'Kultur']
      }
    }
  };

  return (
    <DashboardLayout>
      <div>
        <h2 className="text-3xl font-bold text-amber-900 mb-6">Willkommen bei deiner Abenteuerkiste</h2>

        {/* Ersteinrichtungs-Banner */}
        <div className="mb-8 bg-gradient-to-r from-amber-500 to-amber-600 rounded-xl shadow-lg p-6 text-white">
          <div className="flex items-start gap-4">
            <div className="bg-white/20 rounded-full p-3">
              <Wrench className="w-8 h-8" />
            </div>
            <div className="flex-grow">
              <h3 className="text-xl font-bold mb-2">Ersteinrichtung deiner Abenteuerkiste</h3>
              <p className="text-amber-100 mb-4">
                Bevor du mit den Geschichten loslegen kannst, lass uns deine Abenteuerkiste einrichten. 
                Folge unserer Schritt-für-Schritt-Anleitung, um deine Box mit dem WLAN zu verbinden.
              </p>
              <button
                onClick={() => navigate('/setup')}
                className="inline-flex items-center gap-2 px-6 py-3 bg-white text-amber-600 rounded-lg hover:bg-amber-50 transition-colors duration-200 font-semibold"
              >
                <span>Zur Einrichtung</span>
                <ArrowRight className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>

        {/* Stundenübersicht */}
        <div className="mb-8 grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Stundenverbrauch */}
          <div className="col-span-2 bg-white rounded-xl shadow-lg border border-amber-200 p-6">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-3">
                <Clock className="w-6 h-6 text-amber-600" />
                <h3 className="text-xl font-bold text-amber-900">Deine Geschichtenstunden</h3>
              </div>
              <span className="text-sm text-amber-600">
                Gültig bis {subscriptionData.validUntil}
              </span>
            </div>

            {/* Fortschrittsbalken */}
            <div className="mb-4">
              <div className="w-full h-4 bg-amber-100 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-amber-600 transition-all duration-500"
                  style={{ width: `${progressPercentage}%` }}
                />
              </div>
              <div className="flex justify-between mt-2 text-sm text-amber-700">
                <span>{subscriptionData.usedHours} Stunden verbraucht</span>
                <span>{subscriptionData.remainingHours} Stunden verfügbar</span>
              </div>
            </div>

            {/* Abo-Details */}
            <div className="bg-amber-50 rounded-lg p-4 mb-4">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-amber-900 font-semibold">{subscriptionData.plan}-Abo</p>
                  <p className="text-amber-700">{subscriptionData.totalHours} Stunden pro Monat</p>
                </div>
                <button
                  onClick={() => navigate('/subscription')}
                  className="px-4 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-500 transition-colors duration-200"
                >
                  Abo verwalten
                </button>
              </div>
            </div>

            {/* Warnung bei wenig verbleibender Zeit */}
            {subscriptionData.remainingHours < 1 && (
              <div className="flex items-start gap-3 bg-amber-50 rounded-lg p-4 text-amber-800">
                <AlertCircle className="w-5 h-5 flex-shrink-0 mt-0.5" />
                <p>Deine Geschichtenstunden gehen zur Neige. Upgrade jetzt dein Abo für mehr Geschichtenspaß!</p>
              </div>
            )}
          </div>

          {/* Letzte Geschichten */}
          <div className="bg-white rounded-xl shadow-lg border border-amber-200 p-6">
            <div className="flex items-center gap-3 mb-4">
              <History className="w-6 h-6 text-amber-600" />
              <h3 className="text-xl font-bold text-amber-900">Letzte Geschichten</h3>
            </div>
            <div className="space-y-4">
              {subscriptionData.recentStories.map((story, index) => (
                <div key={index} className="border-b border-amber-100 last:border-0 pb-3 last:pb-0">
                  <p className="font-medium text-amber-900">{story.title}</p>
                  <div className="flex justify-between text-sm text-amber-600">
                    <span>{story.date}</span>
                    <span>{story.duration} Min.</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

    
        {/* Alterseinstellungen */}
        <div className="bg-white rounded-xl shadow-lg border border-amber-200 p-6 mb-8">
          <div className="flex items-center gap-3 mb-6">
            <Brain className="w-6 h-6 text-amber-600" />
            <h3 className="text-xl font-bold text-amber-900">Altersgerechte Einstellungen</h3>
          </div>
          
          <div className="space-y-6">
            {/* Altersgruppen-Auswahl */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {Object.entries(ageGroups).map(([key, group]) => (
                <button
                  key={key}
                  onClick={() => setSelectedAgeGroup(key)}
                  className={`p-4 rounded-lg border ${
                    selectedAgeGroup === key 
                      ? 'border-amber-600 bg-amber-50' 
                      : 'border-gray-200 hover:border-amber-300'
                  } transition-all duration-200`}
                >
                  <h4 className="font-bold text-amber-900 mb-2">{group.label}</h4>
                  <p className="text-sm text-amber-700">{group.description}</p>
                </button>
              ))}
            </div>

            {/* Ausgewählte Einstellungen */}
            {selectedAgeGroup && (
              <div className="mt-6 space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  <div className="bg-amber-50 p-4 rounded-lg">
                    <div className="flex items-center gap-2 mb-3">
                      <Brain className="w-5 h-5 text-amber-600" />
                      <h5 className="font-semibold text-amber-900">Vokabular</h5>
                    </div>
                    <p className="text-amber-700">{ageGroups[selectedAgeGroup].settings.vocabulary}</p>
                  </div>
                  
                  <div className="bg-amber-50 p-4 rounded-lg">
                    <div className="flex items-center gap-2 mb-3">
                      <Star className="w-5 h-5 text-amber-600" />
                      <h5 className="font-semibold text-amber-900">Komplexität</h5>
                    </div>
                    <p className="text-amber-700">{ageGroups[selectedAgeGroup].settings.complexity}</p>
                  </div>
                  
                  <div className="bg-amber-50 p-4 rounded-lg">
                    <div className="flex items-center gap-2 mb-3">
                      <Clock className="w-5 h-5 text-amber-600" />
                      <h5 className="font-semibold text-amber-900">Geschichtenlänge</h5>
                    </div>
                    <p className="text-amber-700">{ageGroups[selectedAgeGroup].settings.length}</p>
                  </div>
                </div>

                <div className="bg-amber-50 p-4 rounded-lg">
                  <div className="flex items-center gap-2 mb-3">
                    <Book className="w-5 h-5 text-amber-600" />
                    <h5 className="font-semibold text-amber-900">Verfügbare Themen</h5>
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {ageGroups[selectedAgeGroup].settings.themes.map((theme, index) => (
                      <span
                        key={index}
                        className="px-3 py-1 bg-white rounded-full text-amber-700 border border-amber-200"
                      >
                        {theme}
                      </span>
                    ))}
                  </div>
                </div>

                <div className="flex justify-end">
                  <button
                    className="px-6 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-500 transition-colors duration-200 flex items-center gap-2"
                  >
                    <Settings className="w-4 h-4" />
                    <span>Einstellungen speichern</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Sicherheitshinweis */}
        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 flex items-start gap-3">
          <Shield className="w-5 h-5 text-blue-600 flex-shrink-0 mt-1" />
          <p className="text-blue-700 text-sm">
            Die Inhalte werden automatisch an die gewählte Altersgruppe angepasst. 
            Alle Geschichten werden auf altersgerechte Sprache und Themen geprüft.
          </p>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;