import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

// Import der Seiten
import LandingPage from '../src/pages/LandingPage';
import Dashboard from '../src/pages/Dashboard';
import AccountSettings from '../src/pages/AccountSettings';
import BoxSettings from '../src/pages/BoxSettings';
import Subscription from '../src/pages/Subscription';
import FAQ from '../src/pages/FAQ';
import Setup from '../src/pages/Setup';
import PrivateRoute from './components/PrivateRoute';

const msalInstance = new PublicClientApplication(msalConfig);

// Handle Redirect Promise
msalInstance.handleRedirectPromise().catch(err => {
  console.error(err);
});

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <Router>
        <Routes>
          {/* Öffentliche Route */}
          <Route path="/" element={<LandingPage />} />
          
          {/* Geschützte Routen */}
          <Route path="/dashboard" element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          } />
          <Route path="/account" element={
            <PrivateRoute>
              <AccountSettings />
            </PrivateRoute>
          } />        
          <Route path="/subscription" element={
            <PrivateRoute>
              <Subscription />
            </PrivateRoute>
          } />
          <Route path="/faq" element={
            <PrivateRoute>
              <FAQ />
            </PrivateRoute>
          } />
          <Route path="/setup" element={
            <PrivateRoute>
              <Setup />
            </PrivateRoute>
          } />
        </Routes>
      </Router>
    </MsalProvider>
  );
}

export default App;