import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { Book, Sparkles, Heart, Mail, ShoppingBag, X, Cpu, Box, Zap, Wifi, CreditCard, Send, Brain, Rocket, Castle, TreeDeciduous, Globe } from 'lucide-react';

function LandingPage() {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const [showNewsletterAlert, setShowNewsletterAlert] = useState(false);
  const [showPreorderAlert, setShowPreorderAlert] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [email, setEmail] = useState('');
  const [selectedStory, setSelectedStory] = useState(null);
  const [storyStep, setStoryStep] = useState(0);

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async () => {
    try {
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  const handleNewsletter = (e) => {
    e.preventDefault();
    if (email) {
      setShowNewsletterAlert(true);
      setEmail('');
      setTimeout(() => setShowNewsletterAlert(false), 3000);
    }
  };

  const handlePreorder = () => setShowProductModal(true);
  const handlePreorderConfirm = () => {
    setShowProductModal(false);
    setShowPreorderAlert(true);
    setTimeout(() => setShowPreorderAlert(false), 3000);
  };

  const storyExamples = [
    {
      category: "Weltraum",
      title: "Abenteuer auf der Raumstation Alpha",
      preview: "Entdecke mit Sarah und Tom die Geheimnisse der Schwerelosigkeit und lerne spannende Fakten über das Leben im All.",
      learningPoints: ["Schwerkraft", "Raumstationen", "Astronautenalltag"],
      icon: <Rocket className="w-12 h-12 text-amber-600" />,
      storySteps: [
        {
          text: "Sarah und Tom schweben durch die Raumstation. Plötzlich hören sie einen Alarm!",
          choices: ["Den Kapitän informieren", "Selbst nachsehen"]
        }
      ]
    },
    {
      category: "Mittelalter",
      title: "Der geheimnisvolle Burgschatz",
      preview: "Begleite Ritter Roland auf seiner Quest und erfahre, wie Menschen im Mittelalter lebten.",
      learningPoints: ["Burgleben", "Ritterkultur", "Mittelalterliche Berufe"],
      icon: <Castle className="w-12 h-12 text-amber-600" />,
      storySteps: [
        {
          text: "Ritter Roland entdeckt eine alte Karte in der Burgbibliothek.",
          choices: ["Die Karte studieren", "Den Burgherren fragen"]
        }
      ]
    },
    {
      category: "Natur",
      title: "Das Geheimnis des alten Waldes",
      preview: "Erkunde mit Emma die faszinierende Welt der Pflanzen und Tiere im Wald.",
      learningPoints: ["Ökosysteme", "Waldtiere", "Photosynthese"],
      icon: <TreeDeciduous className="w-12 h-12 text-amber-600" />,
      storySteps: [
        {
          text: "Emma entdeckt seltsame Spuren im Waldboden.",
          choices: ["Den Spuren folgen", "Erst mehr Information sammeln"]
        }
      ]
    }
  ];

  const ProductModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl max-w-3xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-start mb-6">
            <h2 className="text-3xl font-bold text-amber-900">Abenteuerkiste</h2>
            <button 
              onClick={() => setShowProductModal(false)}
              className="p-1 hover:bg-amber-100 rounded-full"
            >
              <X className="w-6 h-6 text-amber-900" />
            </button>
          </div>
          <div className="space-y-6">
            <div className="bg-amber-50 p-6 rounded-lg">
              <h3 className="text-2xl font-bold text-amber-900 mb-4">
                Der KI-gestützte Geschichtenerzähler für Kinder
              </h3>
              <p className="text-lg text-amber-700 mb-4">
                Die Abenteuerkiste verbindet modernste KI-Technologie mit grenzenloser Kreativität, um einzigartige, spannende und lehrreiche Geschichten für Kinder zu erschaffen.
              </p>
            </div>
            <div className="grid md:grid-cols-2 gap-4">
              <div className="p-4 bg-white border border-amber-200 rounded-lg">
                <div className="flex items-center gap-3 mb-2">
                  <Cpu className="w-6 h-6 text-amber-600" />
                  <h4 className="font-semibold text-lg">Leistungsstarke Hardware</h4>
                </div>
                <p className="text-amber-700">
                  Mit einem Raspberry Pi 3 A+, 512MB RAM und 4 Kernen für flüssige Geschichtengenerierung, 2-Watt-Lautsprecher für kristallklare Aufnahmen und einer Micro-SD Karte für ausreichend Speicherplatz.
                </p>
              </div>
              <div className="p-4 bg-white border border-amber-200 rounded-lg">
                <div className="flex items-center gap-3 mb-2">
                  <Box className="w-6 h-6 text-amber-600" />
                  <h4 className="font-semibold text-lg">Hochwertiges Gehäuse</h4>
                </div>
                <p className="text-amber-700">
                  3D-gedrucktes Design mit kindgerechter, robuster Konstruktion und integriertem LED-Beleuchtungsset für magische Effekte
                </p>
              </div>
              <div className="p-4 bg-white border border-amber-200 rounded-lg">
                <div className="flex items-center gap-3 mb-2">
                  <Zap className="w-6 h-6 text-amber-600" />
                  <h4 className="font-semibold text-lg">Intuitive Steuerung</h4>
                </div>
                <p className="text-amber-700">
                  LED-Beleuchtung und einfache Bedienung für ein magisches Erlebnis
                </p>
              </div>
              <div className="p-4 bg-white border border-amber-200 rounded-lg">
                <div className="flex items-center gap-3 mb-2">
                  <Wifi className="w-6 h-6 text-amber-600" />
                  <h4 className="font-semibold text-lg">Kabellose Verbindung</h4>
                </div>
                <p className="text-amber-700">
                  WLAN-Anbindung für regelmäßige Updates und neue Geschichten
                </p>
              </div>
            </div>
            <div className="bg-amber-50 p-6 rounded-lg">
              <h3 className="text-xl font-bold text-amber-900 mb-3">Lieferumfang</h3>
              <ul className="list-disc list-inside space-y-2 text-amber-700">
                <li>Abenteuerkiste (Erste Edition)</li>
                <li>USB-Netzteil</li>
                <li>Schnellstartanleitung</li>
              </ul>
            </div>
            <div className="bg-amber-800 text-white p-6 rounded-lg">
              <div className="flex flex-col md:flex-row justify-between items-center gap-4">
                <div>
                  <p className="text-2xl font-bold">99,00 €</p>
                  <p className="text-amber-200">zzgl. 5,99 € Versand</p>
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex gap-2">
                    <CreditCard className="w-6 h-6" />
                    <img src="/paypal-light.svg" alt="PayPal" className="h-6" />
                  </div>
                  <button
                    onClick={handlePreorderConfirm}
                    className="px-6 py-3 bg-amber-600 hover:bg-amber-500 rounded-lg shadow-lg transition-all duration-200 inline-flex items-center gap-2"
                  >
                    <Send className="w-5 h-5" />
                    <span>Jetzt vorbestellen</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const StoryPreview = ({ story }) => (
    <div className="bg-white p-6 rounded-xl shadow-lg border border-amber-200 hover:border-amber-400 transition-all duration-200">
      <div className="flex items-center gap-4 mb-4">
        {story.icon}
        <div>
          <h4 className="text-xl font-bold text-amber-900">{story.title}</h4>
          <p className="text-amber-600">{story.category}</p>
        </div>
      </div>
      <p className="text-amber-700 mb-4">{story.preview}</p>
      <div className="space-y-2">
        <p className="font-semibold text-amber-800">Das lernt dein Kind:</p>
        <ul className="list-disc list-inside text-amber-600">
          {story.learningPoints.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
      <button
        onClick={() => setSelectedStory(story)}
        className="mt-4 px-4 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-500 transition-colors duration-200"
      >
        Geschichte starten
      </button>
    </div>
  );

  const InteractiveDemo = () => (
    <div className="bg-amber-50 p-6 rounded-xl border border-amber-200">
      <h3 className="text-2xl font-bold text-amber-900 mb-4">So funktioniert's:</h3>
      <div className="space-y-4">
        <div className="flex items-center gap-4">
          <div className="bg-amber-600 text-white rounded-full w-8 h-8 flex items-center justify-center font-bold">1</div>
          <p className="text-amber-700">Wähle ein Thema oder lass dir eines vorschlagen</p>
        </div>
        <div className="flex items-center gap-4">
          <div className="bg-amber-600 text-white rounded-full w-8 h-8 flex items-center justify-center font-bold">2</div>
          <p className="text-amber-700">Die KI entwickelt personalisierte Geschichten-Optionen</p>
        </div>
        <div className="flex items-center gap-4">
          <div className="bg-amber-600 text-white rounded-full w-8 h-8 flex items-center justify-center font-bold">3</div>
          <p className="text-amber-700">Entscheide selbst, wie die Geschichte weitergehen soll</p>
        </div>
        <div className="flex items-center gap-4">
          <div className="bg-amber-600 text-white rounded-full w-8 h-8 flex items-center justify-center font-bold">4</div>
          <p className="text-amber-700">Lerne spielerisch neue Dinge über dein gewähltes Thema</p>
        </div>
      </div>
    </div>
  );

  const StoryModal = () => {
    if (!selectedStory) return null;
    const currentStep = selectedStory.storySteps[storyStep];

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-xl max-w-2xl w-full p-6">
          <div className="flex justify-between items-start mb-6">
            <h2 className="text-2xl font-bold text-amber-900">{selectedStory.title}</h2>
            <button 
              onClick={() => {
                setSelectedStory(null);
                setStoryStep(0);
              }}
              className="p-1 hover:bg-amber-100 rounded-full"
            >
              <X className="w-6 h-6 text-amber-900" />
            </button>
          </div>
          <div className="space-y-6">
            <p className="text-lg text-amber-700">{currentStep.text}</p>
            <div className="space-y-3">
              {currentStep.choices.map((choice, index) => (
                <button
                  key={index}
                  onClick={() => setStoryStep(storyStep + 1)}
                  className="w-full px-4 py-3 bg-amber-100 hover:bg-amber-200 text-amber-900 rounded-lg text-left transition-colors duration-200"
                >
                  {choice}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-amber-50 relative">
      {showNewsletterAlert && (
        <div className="fixed top-4 right-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-lg shadow-lg">
          Danke für deine Newsletter-Anmeldung! Wir halten dich auf dem Laufenden.
        </div>
      )}
      {showPreorderAlert && (
        <div className="fixed top-4 right-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-lg shadow-lg">
          Vielen Dank für deine Vorbestellung! Weitere Informationen folgen per E-Mail.
        </div>
      )}
      {showProductModal && <ProductModal />}
      {selectedStory && <StoryModal />}
      
      <header className="bg-gradient-to-r from-amber-700 to-amber-600 text-amber-50 shadow-lg">
        <div className="max-w-6xl mx-auto p-6">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-4xl font-bold tracking-tight">Abenteuerkiste</h1>
              <p className="text-amber-200 mt-1">Entdecke & lerne durch spannende Abenteuer</p>
            </div>
            <div>
              {isAuthenticated ? (
                <button
                  onClick={handleLogout}
                  className="px-4 py-2 bg-amber-800 hover:bg-amber-700 text-white rounded-lg transition-colors duration-200"
                >
                  Abmelden
                </button>
              ) : (
                <button
                  onClick={handleLogin}
                  className="px-4 py-2 bg-amber-800 hover:bg-amber-700 text-white rounded-lg transition-colors duration-200"
                >
                  Anmelden
                </button>
              )}
            </div>
          </div>
        </div>
      </header>

      <section className="py-20 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-5xl font-bold text-amber-900 mb-6 leading-tight">
            Willkommen in der Welt der Abenteuerkiste!
          </h2>
          <p className="text-xl text-amber-700 mb-10 leading-relaxed">
            Erlebe die Zukunft des interaktiven Lernens mit unserer KI-gestützten Abenteuerkiste. 
            Unendliche Geschichten, grenzenlose Kreativität und spannende Abenteuer warten auf dich - 
            und dabei lernt dein Kind spielerisch Neues über die Welt!
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button
              onClick={isAuthenticated ? () => console.log('Start adventure') : handleLogin}
              
              className="px-8 py-4 bg-amber-600 hover:bg-amber-500 text-white rounded-lg shadow-xl hover:shadow-2xl transition-all duration-200 transform hover:-translate-y-1 inline-flex items-center gap-3 font-semibold text-lg disabled:opacity-50"
            >
              <span>{isAuthenticated ? 'Jetzt einsteigen' : 'Anmelden'}</span>
              <Book className="w-6 h-6" />
            </button>
            <button
              onClick={handlePreorder}
              className="px-8 py-4 bg-amber-800 hover:bg-amber-700 text-white rounded-lg shadow-xl hover:shadow-2xl transition-all duration-200 transform hover:-translate-y-1 inline-flex items-center gap-3 font-semibold text-lg"
            >
              <span>Jetzt vorbestellen</span>
              <ShoppingBag className="w-6 h-6" />
            </button>
          </div>
        </div>
      </section>

      <section className="py-20 px-4 bg-white">
        <div className="max-w-6xl mx-auto">
          <h3 className="text-3xl font-bold text-center text-amber-900 mb-4">Lernen war noch nie so spannend!</h3>
          <p className="text-center text-amber-700 text-lg mb-12">
            Unsere KI erschafft nicht nur spannende, sondern auch lehrreiche Geschichten, 
            die sich perfekt an das Alter und die Interessen deines Kindes anpassen.
          </p>
          <div className="grid md:grid-cols-4 gap-8">
            <FeatureCard 
              icon={<Brain className="w-8 h-8" />} 
              title="Altersgerechtes Lernen" 
              description="Inhalte werden automatisch an das Alter und Vorwissen deines Kindes angepasst."
            />
            <FeatureCard 
              icon={<Globe className="w-8 h-8" />} 
              title="Vielfältige Themen" 
              description="Von Geschichte über Naturwissenschaften bis hin zu Alltagsthemen."
            />
            <FeatureCard 
              icon={<Sparkles className="w-8 h-8" />} 
              title="Interaktives Erleben" 
              description="Kinder bestimmen selbst, wie sich ihre Geschichte entwickelt."
            />
            <FeatureCard 
              icon={<Heart className="w-8 h-8" />} 
              title="Spielerischer Ansatz" 
              description="Lernen durch spannende Abenteuer und eigene Entscheidungen."
            />
          </div>
        </div>
      </section>

      <section className="py-20 px-4 bg-amber-100">
        <div className="max-w-6xl mx-auto">
          <h3 className="text-3xl font-bold text-center text-amber-900 mb-4">Beispiel-Abenteuer</h3>
          <p className="text-center text-amber-700 text-lg mb-12">
            Entdecke, welche spannenden und lehrreichen Geschichten die Abenteuerkiste erzählen kann
          </p>
          <div className="grid md:grid-cols-3 gap-8 mb-12">
            {storyExamples.map((story, index) => (
              <StoryPreview key={index} story={story} />
            ))}
          </div>
          <InteractiveDemo />
        </div>
      </section>

      <section className="py-16 px-4 bg-white">
        <div className="max-w-4xl mx-auto text-center">
          <h3 className="text-3xl font-bold text-amber-900 mb-6">Bleib auf dem Laufenden</h3>
          <p className="text-lg text-amber-700 mb-8">
            Melde dich für unseren Newsletter an und erhalte Updates zu neuen Features, 
            Geschichten-Themen und dem Verkaufsstart der Abenteuerkiste.
          </p>
          <form onSubmit={handleNewsletter} className="max-w-md mx-auto">
            <div className="flex flex-col sm:flex-row gap-3">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Deine E-Mail-Adresse"
                className="flex-1 px-4 py-3 rounded-lg border border-amber-300 focus:outline-none focus:ring-2 focus:ring-amber-500"
                required
              />
              <button 
                type="submit" 
                className="px-6 py-3 bg-amber-600 hover:bg-amber-500 text-white rounded-lg shadow-lg transition-all duration-200 inline-flex items-center gap-2"
              >
                <Mail className="w-5 h-5" />
                <span>Anmelden</span>
              </button>
            </div>
          </form>
        </div>
      </section>

      <footer className="bg-amber-900 text-amber-100 py-12 px-4">
        <div className="max-w-6xl mx-auto">
          <div className="grid md:grid-cols-3 gap-8 mb-8">
            <div>
              <h4 className="font-bold text-xl mb-4">Abenteuerkiste</h4>
              <p className="text-amber-300">Die KI-gestützte Lern- und Geschichtenbox für Kinder</p>
            </div>
            <div>
              <h4 className="font-bold text-xl mb-4">Kontakt</h4>
              <p className="text-amber-300">info@Abenteuerkiste.de</p>
            </div>
            <div>
              <h4 className="font-bold text-xl mb-4">Rechtliches</h4>
              <ul className="text-amber-300 space-y-2">
                <li>Datenschutz</li>
                <li>Impressum</li>
                <li>AGB</li>
              </ul>
            </div>
          </div>
          <div className="text-center pt-8 border-t border-amber-800">
            <p>© 2024 Abenteuerkiste. Alle Rechte vorbehalten.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

function FeatureCard({ icon, title, description }) {
  return (
    <div className="p-6 rounded-xl bg-amber-50 hover:shadow-xl transition-shadow duration-200 border border-amber-200 hover:border-amber-300">
      <div className="text-amber-600 mb-4 flex justify-center">
        {icon}
      </div>
      <h3 className="text-xl font-bold text-amber-900 mb-3">{title}</h3>
      <p className="text-amber-700 leading-relaxed">{description}</p>
    </div>
  );
}

export default LandingPage;