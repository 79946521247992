import React, { useState } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import { User, Key, Mail, CreditCard, AlertTriangle, Shield, Save, Trash2, Info } from 'lucide-react';

const AccountSettings = () => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [formData, setFormData] = useState({
    firstName: 'Max',
    lastName: 'Mustermann',
    email: 'max@beispiel.de',
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <DashboardLayout>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold text-amber-900 mb-6">Kontoeinstellungen</h2>

        {/* Persönliche Daten */}
        <div className="bg-white rounded-xl shadow-lg border border-amber-200 p-6 mb-6">
          <div className="flex items-center gap-3 mb-6">
            <User className="w-6 h-6 text-amber-600" />
            <h3 className="text-xl font-bold text-amber-900">Persönliche Daten</h3>
          </div>
          
          <form className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-amber-700 mb-2" htmlFor="firstName">
                  Vorname
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 rounded-lg border border-amber-300 focus:outline-none focus:ring-2 focus:ring-amber-500"
                />
              </div>
              <div>
                <label className="block text-amber-700 mb-2" htmlFor="lastName">
                  Nachname
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 rounded-lg border border-amber-300 focus:outline-none focus:ring-2 focus:ring-amber-500"
                />
              </div>
            </div>

            <div>
              <label className="block text-amber-700 mb-2" htmlFor="email">
                E-Mail-Adresse
              </label>
              <div className="flex items-center gap-3">
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="flex-1 px-4 py-2 rounded-lg border border-amber-300 focus:outline-none focus:ring-2 focus:ring-amber-500"
                />
                <button 
                  type="button"
                  className="px-4 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-500 transition-colors duration-200 flex items-center gap-2"
                >
                  <Mail className="w-4 h-4" />
                  <span>Bestätigen</span>
                </button>
              </div>
            </div>
          </form>
        </div>

        {/* Passwort ändern */}
        <div className="bg-white rounded-xl shadow-lg border border-amber-200 p-6 mb-6">
          <div className="flex items-center gap-3 mb-6">
            <Key className="w-6 h-6 text-amber-600" />
            <h3 className="text-xl font-bold text-amber-900">Passwort ändern</h3>
          </div>
          
          <form className="space-y-4">
            <div>
              <label className="block text-amber-700 mb-2" htmlFor="currentPassword">
                Aktuelles Passwort
              </label>
              <input
                type="password"
                id="currentPassword"
                name="currentPassword"
                value={formData.currentPassword}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg border border-amber-300 focus:outline-none focus:ring-2 focus:ring-amber-500"
              />
            </div>

            <div>
              <label className="block text-amber-700 mb-2" htmlFor="newPassword">
                Neues Passwort
              </label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg border border-amber-300 focus:outline-none focus:ring-2 focus:ring-amber-500"
              />
            </div>

            <div>
              <label className="block text-amber-700 mb-2" htmlFor="confirmPassword">
                Neues Passwort bestätigen
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg border border-amber-300 focus:outline-none focus:ring-2 focus:ring-amber-500"
              />
            </div>

            <div className="flex justify-end">
              <button 
                type="button"
                className="px-6 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-500 transition-colors duration-200 flex items-center gap-2"
              >
                <Save className="w-4 h-4" />
                <span>Passwort speichern</span>
              </button>
            </div>
          </form>
        </div>

        {/* Konto löschen */}
        <div className="bg-red-50 rounded-xl shadow-lg border border-red-200 p-6">
          <div className="flex items-center gap-3 mb-6">
            <AlertTriangle className="w-6 h-6 text-red-600" />
            <h3 className="text-xl font-bold text-red-900">Konto löschen</h3>
          </div>

          {!showDeleteConfirm ? (
            <div>
              <p className="text-red-700 mb-6">
                Wenn du dein Konto löschst, werden alle deine Daten und Einstellungen unwiderruflich gelöscht. 
                Dein Abonnement wird zum Ende der aktuellen Laufzeit gekündigt.
              </p>
              <button
                onClick={() => setShowDeleteConfirm(true)}
                className="px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-500 transition-colors duration-200 flex items-center gap-2"
              >
                <Trash2 className="w-4 h-4" />
                <span>Konto löschen</span>
              </button>
            </div>
          ) : (
            <div>
              <p className="text-red-700 mb-4">
                Bist du sicher, dass du dein Konto löschen möchtest? Diese Aktion kann nicht rückgängig gemacht werden.
              </p>
              <div className="flex gap-4">
                <button
                  onClick={() => setShowDeleteConfirm(false)}
                  className="px-6 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-500 transition-colors duration-200"
                >
                  Abbrechen
                </button>
                <button
                  className="px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-500 transition-colors duration-200 flex items-center gap-2"
                >
                  <Trash2 className="w-4 h-4" />
                  <span>Endgültig löschen</span>
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Datenschutz-Info */}
        <div className="mt-6 bg-blue-50 border border-blue-200 rounded-lg p-4 flex items-start gap-3">
          <Info className="w-5 h-5 text-blue-600 flex-shrink-0 mt-1" />
          <p className="text-blue-700 text-sm">
            Wir behandeln deine persönlichen Daten vertraulich und in Übereinstimmung mit den Datenschutzbestimmungen. 
            Mehr Informationen findest du in unserer Datenschutzerklärung.
          </p>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AccountSettings;