import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Home, User, Settings, CreditCard, Book, HelpCircle } from 'lucide-react';

const DashboardLayout = ({ children }) => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  const menuItems = [
    { icon: <Home className="w-5 h-5" />, label: 'Dashboard', path: '/dashboard' },
    { icon: <User className="w-5 h-5" />, label: 'Konto', path: '/account' },
    { icon: <Settings className="w-5 h-5" />, label: 'Box-Einstellungen', path: '/box-settings' },
    { icon: <CreditCard className="w-5 h-5" />, label: 'Abo verwalten', path: '/subscription' },
    { icon: <Book className="w-5 h-5" />, label: 'Einrichtung', path: '/setup' },
    { icon: <HelpCircle className="w-5 h-5" />, label: 'FAQ', path: '/faq' },
  ];

  return (
    <div className="min-h-screen bg-amber-50 flex">
      {/* Sidebar */}
      <div className="w-64 bg-amber-800 text-white p-4">
        <div className="mb-8">
          <h1 className="text-2xl font-bold">Abenteuerkiste</h1>
        </div>
        <nav className="space-y-2">
          {menuItems.map((item) => (
            <button
              key={item.path}
              onClick={() => navigate(item.path)}
              className="w-full flex items-center gap-3 px-4 py-2 rounded-lg hover:bg-amber-700 transition-colors duration-200"
            >
              {item.icon}
              <span>{item.label}</span>
            </button>
          ))}
        </nav>
        <button
          onClick={handleLogout}
          className="mt-8 w-full px-4 py-2 bg-amber-900 hover:bg-amber-700 rounded-lg transition-colors duration-200"
        >
          Abmelden
        </button>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-8">
        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;