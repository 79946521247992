import React, { useState } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import { Clock, Check, CreditCard, AlertCircle, Shield, Star } from 'lucide-react';

const Subscription = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const plans = [
    {
      id: 'basic',
      name: 'Einstieg',
      price: '9,99',
      hours: 2,
      features: [
        '2 Stunden Geschichten pro Monat',
        'Alle Themenbereiche verfügbar',
        'Personalisierte Geschichten',
        'Altersgerechte Anpassung'
      ],
      color: 'bg-gradient-to-br from-amber-500 to-amber-600'
    },
    {
      id: 'standard',
      name: 'Standard',
      price: '19,99',
      hours: 6,
      features: [
        '6 Stunden Geschichten pro Monat',
        'Alle Themenbereiche verfügbar',
        'Personalisierte Geschichten',
        'Altersgerechte Anpassung',
        'Bevorzugter Support'
      ],
      color: 'bg-gradient-to-br from-amber-600 to-amber-700',
      popular: true
    },
    {
      id: 'premium',
      name: 'Unbegrenzt',
      price: '99,99',
      hours: 60,
      features: [
        '60 Stunden Geschichten pro Monat',
        'Alle Themenbereiche verfügbar',
        'Personalisierte Geschichten',
        'Altersgerechte Anpassung',
        'Premium Support',
        'Exklusive Themen',
        'Keine Wartezeiten'
      ],
      color: 'bg-gradient-to-br from-amber-700 to-amber-800'
    }
  ];

  const PaymentModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl max-w-lg w-full p-6">
        <h3 className="text-2xl font-bold text-amber-900 mb-6">Abo abschließen</h3>
        <div className="space-y-4">
          <div className="p-4 bg-amber-50 rounded-lg">
            <div className="flex justify-between items-center mb-2">
              <span className="font-semibold text-amber-900">Gewähltes Abo:</span>
              <span className="text-amber-700">{selectedPlan?.name}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="font-semibold text-amber-900">Monatlicher Betrag:</span>
              <span className="text-amber-700">{selectedPlan?.price}€</span>
            </div>
          </div>
          
          <button
            onClick={() => setShowPaymentModal(false)}
            className="w-full px-6 py-3 bg-amber-600 text-white rounded-lg hover:bg-amber-500 transition-colors duration-200 flex items-center justify-center gap-2"
          >
            <CreditCard className="w-5 h-5" />
            <span>Jetzt kostenpflichtig bestellen</span>
          </button>
          
          <button
            onClick={() => setShowPaymentModal(false)}
            className="w-full px-6 py-3 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors duration-200"
          >
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <DashboardLayout>
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-amber-900 mb-4">Wähle dein Abenteuer-Abo</h2>
          <p className="text-lg text-amber-700">
            Entdecke grenzenlose Geschichten mit unserem flexiblen Abo-Modell
          </p>
        </div>

        {/* Abo-Karten */}
        <div className="grid md:grid-cols-3 gap-8 mb-12">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className={`relative bg-white rounded-xl shadow-xl overflow-hidden ${
                plan.popular ? 'ring-2 ring-amber-500' : ''
              }`}
            >
              {plan.popular && (
                <div className="absolute top-4 right-4">
                  <span className="bg-amber-600 text-white px-3 py-1 rounded-full text-sm font-medium flex items-center gap-1">
                    <Star className="w-4 h-4" />
                    Beliebt
                  </span>
                </div>
              )}

              <div className={`${plan.color} text-white p-6`}>
                <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
                <div className="flex items-baseline">
                  <span className="text-3xl font-bold">{plan.price}€</span>
                  <span className="ml-2 text-amber-100">/Monat</span>
                </div>
                <div className="mt-4 flex items-center gap-2">
                  <Clock className="w-5 h-5" />
                  <span>{plan.hours} Stunden/Monat</span>
                </div>
              </div>

              <div className="p-6">
                <ul className="space-y-4">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-start gap-3">
                      <Check className="w-5 h-5 text-amber-600 flex-shrink-0 mt-0.5" />
                      <span className="text-amber-700">{feature}</span>
                    </li>
                  ))}
                </ul>

                <button
                  onClick={() => {
                    setSelectedPlan(plan);
                    setShowPaymentModal(true);
                  }}
                  className="w-full mt-6 px-6 py-3 bg-amber-600 text-white rounded-lg hover:bg-amber-500 transition-colors duration-200"
                >
                  Abo auswählen
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Zusätzliche Informationen */}
        <div className="grid md:grid-cols-2 gap-6">
          <div className="bg-amber-50 rounded-lg p-6 flex items-start gap-4">
            <Shield className="w-6 h-6 text-amber-600 flex-shrink-0" />
            <div>
              <h4 className="font-semibold text-amber-900 mb-2">Flexible Laufzeit</h4>
              <p className="text-amber-700">
                Alle Abos sind monatlich kündbar. Die Kündigung ist jederzeit zum Ende des Abrechnungszeitraums möglich.
              </p>
            </div>
          </div>

          <div className="bg-amber-50 rounded-lg p-6 flex items-start gap-4">
            <AlertCircle className="w-6 h-6 text-amber-600 flex-shrink-0" />
            <div>
              <h4 className="font-semibold text-amber-900 mb-2">Nicht genutzte Stunden</h4>
              <p className="text-amber-700">
                Nicht genutzte Stunden verfallen am Ende des Monats und können nicht in den nächsten Monat übertragen werden.
              </p>
            </div>
          </div>
        </div>

        {showPaymentModal && <PaymentModal />}
      </div>
    </DashboardLayout>
  );
};

export default Subscription;