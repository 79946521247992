import { LogLevel } from "@azure/msal-browser";

const tenantName = "AbenteuerKiste1";
const tenantId = "4982166d-5384-410a-b47d-71ba5f54d845";
const clientId = "9e184b20-3634-4e0a-bcec-ceb8ce969f29";
export const API_SCOPE = "https://" + tenantName + ".onmicrosoft.com/api/access_as_user";

const policyNames = {
    signIn: "B2C_1_SignIn",
    signUp: "B2C_1_SignUp",
    signUpSignIn: "B2C_1_signupsignin1",
};

const authorityBase = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com`;

const b2cPolicies = {
    names: policyNames,
    authorities: {
        signIn: {
            authority: `${authorityBase}/${policyNames.signIn}`,
        },
        signUp: {
            authority: `${authorityBase}/${policyNames.signUp}`,
        },
        signUpSignIn: { authority: `${authorityBase}/${policyNames.signUpSignIn}`,
    },
    },
    authorityDomain: `${tenantName}.b2clogin.com`,
};

const msalConfig = {
    auth: {
        clientId: clientId,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: "/",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        break;
                    case LogLevel.Info:
                        console.info(message);
                        break;
                    case LogLevel.Verbose:
                        console.debug(message);
                        break;
                    case LogLevel.Warning:
                        console.warn(message);
                        break;
                }
            },
        },
    },
};

const loginRequest = {
    scopes: ["openid", "profile"],
};

export { msalConfig, loginRequest, b2cPolicies };
